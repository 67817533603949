import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { graphql, Link } from "gatsby";
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../../components/Grid';
import { MainProject, CardStaticImg, CardLink, CardContainer, CardBody } from '../../../../components/Card';
import { ButtonGhost } from '../../../../components/Button';
import { designTokens } from '../../../../components/Theme/designTokens';
import projects from '../../../../data/projects';
import { IconBox, IconCard } from '../../../../components/IconBox';
import { Box } from '../../../../components/Box';
import { Text } from '../../../../components/Text';
import { OutboundLink } from '../../../../components/StyledLink';
import { CallToAction } from '../../../../components/CallToAction';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdFlag from 'react-ionicons/lib/MdFlag';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Chargebacks911" mdxType="SEO" />
    <IntroProject projectName="Marketing Design" projectDescription="Helping build Chargeback911's flexible marketing toolbox" projectYear="2016 - 2019" projectImage="cb911-marketing-preview" subProject="Chargebacks911" subProjectLink="chargebacks911" mdxType="IntroProject" />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Overview 📋</h5>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <p style={{
                  marginBottom: '0'
                }}>How can we better reach companies that need assistance with dealing with disputed transactions?</p>
          </CardBody>
        </CardContainer>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <p style={{
                  marginBottom: '0'
                }}>How can we build print and digital marketing assets more rapidly as we experience growth?</p>
          </CardBody>
        </CardContainer>
        <p>After joining Chargebacks911, I saw there wasn't a source of truth nor standardization for the brand's design language. To help instill the brand's mission, vision, and values through a codified form, we created a style guide using our newly created styles. With an organized reference guide, we could onboard other teams, partners, vendors, and third parties to our brand design language quickly and more thoroughly. <strong>I also happen to be a huge nerd for design documentation.</strong></p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Chargebacks911's brand design documentation</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "178.33333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAkCAYAAACJ8xqgAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFWklEQVRIx6VWyW8TVxy21AtS1aonJCIOqagEB/gbUO/AGalURQIh9RgppEJBiCiKqNJWQVBIaKU25NAcINhxiElSKZQWkcXGZMFZDIHEy9iO1/F4Ns/y9fdmxlvi0NI+6fOz5733ze/9ts8ub0zDWFzDw7j+P6GBcblGohqWcjoU3USxbEJ4T7AzEp3dKOoYZYT3IxrWiwasYZrsA43DfDdMe39C0uGJOoSrObWByNpKG3dSv2vERSJkFrpTwMyTAHKvw8jxReTzeei6bm3SNA0lQYDCIElQFAWyLNszAz0XCgVoigxOMmwLR+IGFt+mkeViiMU5cBxnHWJX0Yh0a2ICq8PD6OnpQVdXF769dg09vb3op+9LbW3YnJqCIot0ZaN25bWiCVUsgScLGVnVQiKNLy8jQgguLCDg92OR5oWlJSw+e4bozAzSsRhUZqFYT1hgthjQDaMxHDt+Nxu2n42aDy1C3rbIMJ1gOEAlOERsNIH13NnXSFiwCfU6sn+LCmHDlcOOhf99mOBKZdvCB8T6PFNGVlKRLKlI7YkykoKKWEFEvB68hFiWx4tEkSpFh4uZ+XSbqoWsDOU1rBTeAVoPNQFzmT9bV3phXtvjJuQKg9b0PVBZMzUka0EpY72EWpStmZxtmO/lRU4BBaVMPuSA4PIbaKkEVCo1rVyubtr0eODv7ETg8mXMtrdj7uLFGjo6ELhyBfO0HvymA6/Wt+ClMnY9SAB+/wpSG6/ApVJIJZMQSiWr9MaOHcNPLpeFfge3HNwm/OrMQ4SNSAreJJxuQwGRS9QYqNBZcxCpEUjFIn45fRp3Tp3CwMmT6D9xAv00e8+exe/nz2Py3DlMXbiA8S/O4MV3vUhYV96R2MxrlTqenJzEJy0HsL+1FR98/BFc+/bhw/37cYUaQ3dfHy5Rs2i/ehVfX+zA1JM/kKPDDyJqIyELBmtZbAz0D6DlQAuOHD6CQ62f4iB9//z4cfx44wb6fvgeN69fx8937li/36yvIUMUbgrwLkKrpMhKZm3o5k1MHD2KzcFBJPMFSKKIFPn5zJdfoa2tHdlsFjlyEbubVcvRJs3Bmh0rAxTZAXL4MkU1S9FXqbVtbUVw8GArDn12GDFqXanttNVtErK5u31VOohp2C/ILC4iTNblqAdKqkoppSKfy8E37sP4+DiKPI8SZYSmSNjICE7p0cfjhIoXaQXPtwlpB/R9QQCWiZvNwbRqPQ/Q81URWKHM8qck+i3DnxTxJyeTjBLhSITVo26Jl0yHFaMGuaxDUsrWrNCaatgQZBW6kxUirevkq62SUeuHYcHcIaOOPDq+LKTTuDf8G14uLsB9/x6WyQVroZcIr6xUa5oTnPY1QmaGuCK9WnY6do27Us1l8p/b7UYkGsXdoSHMzs7CMzoKn89XJ6NOg2Uy+uzxPHJvN5AlJwskjSwX62UgHolgzOu16nx6ehpDd+9CpGDIlEa27lDalLSKhSSj0TyySQ4JyrFEIgGByg4s2qxqCDzlW5Kes7QJkvKxerfSi16gkfWgrIhXrlypZVNTIcmKJeJ7Ni7HYlaemUwGBap9nr3cNGpC31DLzoESaS5PPsuTwIvBYFVSWcIbRFZVvTqRaqyUgp0EhnONaHc3timhC48eQQqFGggrL91hdDMZtf8cKVRK20SUn5uz/GM4iWRUCIGmMhqXdsmovZCdncFC5yXEPG68vn2L3iA5thiW85sP+udAiliVUT+VVUpQwFGBr4de4+ngMOZHH2HprzlLb9nmOC8jxivYyos7IGEzwyNYkdGxuC1/TGA81M9YG/dR4MZzwMO0LTz/BHbeSzzsL/HfqLM38eu3VwoAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chargebacks911 Website",
          "title": "Chargebacks911 Website",
          "src": "/static/d53bcbf38d6a406dcba13f6aeaf12f77/d9199/cb911-styles-2.png",
          "srcSet": ["/static/d53bcbf38d6a406dcba13f6aeaf12f77/8ff5a/cb911-styles-2.png 240w", "/static/d53bcbf38d6a406dcba13f6aeaf12f77/e85cb/cb911-styles-2.png 480w", "/static/d53bcbf38d6a406dcba13f6aeaf12f77/d9199/cb911-styles-2.png 960w", "/static/d53bcbf38d6a406dcba13f6aeaf12f77/07a9c/cb911-styles-2.png 1440w", "/static/d53bcbf38d6a406dcba13f6aeaf12f77/29114/cb911-styles-2.png 1920w", "/static/d53bcbf38d6a406dcba13f6aeaf12f77/ad164/cb911-styles-2.png 3680w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Website Design & Blog 👨‍💻</h5>
        <p>To help solidify our product offerings and expertise, as well as organize our content through a linear site architecture, we needed to redesign the main website, landing pages, and overall branding.</p>
        <p>While undergoing a redesign, we also needed to standardize our website components <i>(buttons, typography, navigation menus, links, etc.)</i>. Our goal for a website redesign, similar to many other companies' website redesign goal, is to direct more traffic in hopes to ultimately find readers who would benefit from our platform.</p>
        <p>After redesigning the website and creating a greater amount of interactive content (blog posts, webinars, ebooks), we gathered higher conversion rates, more traffic, and a greater number of qualified sales leads:</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Website redesign - showcasing the homepage</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAAsSAAALEgHS3X78AAACu0lEQVQozy2SXUxSYRjH303FoSTKkYOeQKD8agqSUzdTQxExNdSkSTNq2oXTrC7KZVvr48IL67o1+9iaXXTRWrfazKlZgW5+jYNI4o3zqw0C5BzO4Rzg9II+e/bued49v/3f578XjA4MNFZVmRv1F1SqusoqXXXNZb2hranlkbnn7o2+8Tfv7XZ8bnbO5dpiGIogjmiGjUajHMdFwmHQf9UEABAh2RmZolS+gC8QisS5CCrtu9V/f3hk/scCnKO8Xv/BYZjy+zz7PjJEkkQkEmFpGtzuNkvlirq6i6WlZWJMJsHy0BxZUXGZ1WqbmvrmwB2xWMzn8QR8vijNRAiSS8jCiMND5mvKgkKttqG2VluuLChSFLS3d1mu94YoCk6EaToKRVg2zDCRaBQmAwuWjcMUFVeurK7psdw0XeluFaGmmnrnhnNm+jtJEN69fZIk4RwUj3FcwO8ngsFIoo3DLAvGhh+qzldotbomQ2t9a+fz0bH1tfXX4++WFpccOP5nZcW7sXGI4z6nc+fX7z2bLYTj5Ooq43CQdjvwuFxx69j42yi/PxQMQkMC/kDwKAjvP/f2PQPgBYq+zcqaEAgmUlImMWw+P3+Wx1uWSIA3AcNN6HCYoeFB0zQF21jCmC8WC4RfIciHjIyPSUmfAJhTqxf1+mkeb1UoPIGhdXBJMgRVSYqiIBmLwO24l4NDDTy+EUGNWdmdIrEJQbsFQjNf0HMq897ZwhOY9P7zHf5ljq1IxPFPGHn8tKhEoywsUarKFSWafHUFLOSlGqxYfU5TCQ6sVnpnJ2DH/YtLxOYmtb1NbW3RbjfldnO7uw8G70hyFdLTCixXjmFymeyMTKqEmZOTpypWgQWz+SsAk+npM0LhT7HYhiDLKLqWlracmrqenPxEVXapxdipM7Q3NsM06gwwO/TNbQ16S0fXf87zqWaVh72BAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chargebacks911 Website",
          "title": "Chargebacks911 Website",
          "src": "/static/5cd6ad2fbeffc58e7d898857c909a5bb/d9199/cb911-marketing-1.png",
          "srcSet": ["/static/5cd6ad2fbeffc58e7d898857c909a5bb/8ff5a/cb911-marketing-1.png 240w", "/static/5cd6ad2fbeffc58e7d898857c909a5bb/e85cb/cb911-marketing-1.png 480w", "/static/5cd6ad2fbeffc58e7d898857c909a5bb/d9199/cb911-marketing-1.png 960w", "/static/5cd6ad2fbeffc58e7d898857c909a5bb/07a9c/cb911-marketing-1.png 1440w", "/static/5cd6ad2fbeffc58e7d898857c909a5bb/0d390/cb911-marketing-1.png 1472w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <CSSGrid mdxType="CSSGrid">
  <Col mdxType="Col">
    <Box center bg={({
          theme
        }) => theme.greenTransparent} marginBottom={designTokens.space[6]} paddingY={designTokens.space[6]} mdxType="Box">
      <Container mdxType="Container">
        <CSSGrid mdxType="CSSGrid">
          <Col mdxType="Col">
            <Text center mdxType="Text">After redesigning the website and updating the content strategy, we saw</Text>
            <p className="lead">a 250% growth in website traffic and 400% growth in inbound leads</p>
          </Col>
        </CSSGrid>
      </Container>
    </Box>
  </Col>
    </CSSGrid>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Helping prospective users understand who we are</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "89.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsSAAALEgHS3X78AAACwUlEQVQ4y21S207bQBANpVUlaBH0Ak0B9RW1f9sv6AMPiXhoVRQJJQotVaWqIEpRgpNAYid2fFnHG9vZtb322k7HDregji1rvTtnzpmzUxgMBmPDaJ+fW4oSMEYhPJ/4gdvr+Xhs4hEyTcNAsiIrioIQMuAHIVVVJUkq9Pt95nlJECRJEsfxdDrttxty79LSZEsdXtidtv4HSqrDoWmi8XiMMfY8DxaiKBaA2XVdqIbz4HHcOfs9GMqG0kcD0U9CjSppwqecT9MkzQMIIPMG7DiWZc3ArmOruk4qlcnJMSRRd+LTIObwZC8gwzCE/Ttmx3GGwyG0JMsyIZTaFv76RT89RaYBdQ1kYFBrWZBGCVEVBZTegW3bVvPQNA3cOjzY7w16qtgk9ijTmOu8jeg+c2YYY7dn4Jmhq6aq7H/6OBkj5vnUcQghum6MRhbJhFFQfg0Gx2fg9B4DrMH8jAHuBkzBFrjiYgxg3/c559eGAbOXBWUsgOzAdeMwnBWCbxRFD2TPuQ1gUALjEScJbPuUwnWnN3lRxGEBhJAN9wG0sxLX4Ex2GDLHZq6b6TRN6ALwWrcbMjYDAwYbhp0fPQSDgROEHISyXU0L/SAOmXjR9AnhIBscjiIWBGEQQLfz4H4/mmV4XpyNUcrAlfw+cvOzqeL52P6nZ0kUXbEXSBIVBNrpsG6XNpu03eaimIgid13I5dAI57N3DtxttWof3n97+eLXxvrf7U2h+EZcW9XfbVvFdbJYiMqlNJedzGgfMHcF4WB76/Dpk5+Lj842XgurK92FgrbxCj9fooVCtLsLMFwu4709q1QaVypzPfdarYOdndry0o9ny6dbbxubxc7airxVRMV19/ECK5fINHU1lYwQJyT2vPmeJUk4OhJqtXa93q5WO9XqZb1+VatdVqv45CQl5EpoND6XW8ffecDA8Pvgf9hpuwQbTyB3AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chargebacks911 About Page",
          "title": "Chargebacks911 About Page",
          "src": "/static/f616fa4c4ed3c02d0871419a953b2279/d9199/cb911-marketing-2.png",
          "srcSet": ["/static/f616fa4c4ed3c02d0871419a953b2279/8ff5a/cb911-marketing-2.png 240w", "/static/f616fa4c4ed3c02d0871419a953b2279/e85cb/cb911-marketing-2.png 480w", "/static/f616fa4c4ed3c02d0871419a953b2279/d9199/cb911-marketing-2.png 960w", "/static/f616fa4c4ed3c02d0871419a953b2279/07a9c/cb911-marketing-2.png 1440w", "/static/f616fa4c4ed3c02d0871419a953b2279/29114/cb911-marketing-2.png 1920w", "/static/f616fa4c4ed3c02d0871419a953b2279/49853/cb911-marketing-2.png 2224w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Providing brand assets and content to third-party vendors and partner companies</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "89.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsSAAALEgHS3X78AAADQ0lEQVQ4y21TWWvUUBS+XWinrdaNgpSCjLUq+mAr6v+xVNCKVpGi4oIPIlQUn4QiiuCGnfYHVPowDy6ldhiX2trOdCaZLJNkcpOZbDeZTBZPMlUR/LgJJ+F+5zvn3O8imqYpisrlcsXNTSpGsYl8Pre+vpH7PwqFAhARXSqpkrSxtoY51iLENAxd0wzXrTKMqWmCJEG6UhM0Ha2tMAKiaLpu26HjhK4bAoLAD4LNG9e/jZ62VlZljCmGAQbUQ3McUy6zDEPRW0DwEEIc23Zs4nueX3d+PnywdP3ax7Ex9m1Ksywpnye67ngeYUp4/aeEMWyWZRk6Q1BVvV6PND0PZAGuaUIQxnAIsdbWfPgThr4oahRVFkXYo6pqRIaSDE2rVipWrUosy3Ec0yY2IbZte42GZVnEMALICw1pmiPLdqz0m0zTpmlpuqEbBlBM04R5MDEghixqJtNQ1agymlZWVymG9T1/i/y37BiB7xswcF23TNNzXc/zoAEfmEEAkR8NNOpI+aMMjQWNRvgvmps0Va3wvCJJWBAVUdQVrEHlmqZWKr/LJqTK8wbHBfHYmMzyQjqdmZ8PMC5MTS0dGFw9dTI3fKw0ckwcGVZPHCeHD1Zu3ypKEgJ/wQwsnicU1VSEKcHhQXr4Wjl39h1Ci50dywj9QGgTIbatTUFIGB2lZDlStmy7Jgh6sdhU5r5+Sb//kF1YCHU9N3HxE0IrPd0brS1MW2ultUXvaPcRqp4ZK0ZkUIbzqdXqGDe79cAwhLhguzCcGz9/GaG723vvJbruJxKPOhOPe7ZNI7QwPs7KlUjZMM2aLOPFRR9MGoaN79+5zHK+VIJ48sYt1NGzd/+h7r7+PfuGEn39uwaSCLWMjV/AsTINwuCHBiFbxnKcBojH5zc5ebW1rTOZHOofSCYPHu3t3TM4eGh45OTN23dEUYyVDUPFWMlmYVSR8o8VPpstlBiIJy5dQQjt2NnXvX1317Zd7R09+4eOXLow8XL6CcOyEdmF+wSXCWzk+5GybYNyPb5kS0ufX7x8lUrNzcykZmZmU7Nzr9+8ff702WI6HZHBYSzLcjzPiyK8OYgFoQyrXOY4ThQFDEXhv1AUrFarvCAA8RdHrGL5xim6LQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chargebacks911 Press Resources",
          "title": "Chargebacks911 Press Resources",
          "src": "/static/af2d998cfa3759d515fa3521e9ab8f92/d9199/cb911-marketing-3.png",
          "srcSet": ["/static/af2d998cfa3759d515fa3521e9ab8f92/8ff5a/cb911-marketing-3.png 240w", "/static/af2d998cfa3759d515fa3521e9ab8f92/e85cb/cb911-marketing-3.png 480w", "/static/af2d998cfa3759d515fa3521e9ab8f92/d9199/cb911-marketing-3.png 960w", "/static/af2d998cfa3759d515fa3521e9ab8f92/07a9c/cb911-marketing-3.png 1440w", "/static/af2d998cfa3759d515fa3521e9ab8f92/29114/cb911-marketing-3.png 1920w", "/static/af2d998cfa3759d515fa3521e9ab8f92/49853/cb911-marketing-3.png 2224w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Resources page helping organize the different facets of our marketing strategy</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "89.99999999999999%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAIAAADUsmlHAAAACXBIWXMAAAsSAAALEgHS3X78AAADfklEQVQ4y21TXWgcVRQelUooJVpDKiXFiK2SQmuTQn0RLWq70FiL+CCCKP5VfQzoQ5saRE0ILShtIciaIGUbELubxoSs3aSmNOlmSdqkbNOd6f7OzL0zszO7M3dmZ3Y33f94ZrdqS/24HO6dOd/5znfuDMVzHH9xbOHUKcbp5MbHIx63b3KCuXkzODQ0d/rMbb8/lkhEI5Hog0gkEjzPU3GavrR//9QbByYcjpH29t5HHj100PHz51+cbW7+lKJ+//6HtGlyLIsegk2GF9GFALe8LNK0uLLC3rgRxtgSRTMalRnmTiiEMEa8ncoJmMOYQwii3S+QeYRK5bIsSVYmE5S1K3RM8Xp/dbl+cTp/c7stQnRVzQHyeUtRLNhrWp6QVCrFsiwFBeDV4kJg6fr1OI9m566FFpdGhkfODAyMe8b6+/q8Hk/vV1+7XOcLwaDOMKrfj30+M58H2xToFwuF9YdQrVQgugcHg2NjPT0954aHC9msSQjmeRyLZUzTJkPblqYVdUJPT8VnfIae/3PkIs/G1+jw8o8XitXa+nqtUq1CoVqtVoVVKtXKZZ2QetsIZU1TikYDf4zzK8vxCJp2T4gyklaC7PlLdDjy1+XL8Vh07urVQCDAMIx//trq6qqmaTaZ47j724byjQ1MEeLQyZM72ra9+vIrjgOONw8f7ty9p6lpY1fnXiwI96adsyxD10uVSrFcLtY5hXK50SoKh2cmJy+43bdA7nZodHTUec41OeVVFBlUbfKaZVkI3VWUItFzglAiZC2dtnhUMgwo0eilZqP6b2vknmeM86qWmZ015ufzd8LG9Iy1uJQLhciUd42m06oKCnCrqqqm02kFFBUFmHD8x3Pxf66qoZDJZGRZhmzDMKw6Moaey2b/U86qKsEYKsOjnGkaqZQG2bpeuFvQdV2WlVQKNFP1qMiiqCSTGGHbMxIEU5JgMGAeZgi5KBgUkklJFMFu/8Dg1rb2zr0vvdCxa9fuLoh7uvZte2Z774lvoFjds6apySTRdSubheFpGMOeaBq0ffxEH0VtaH92x1OtbW3PdWxqbmlp3UpR1NGjX4Jt+9sWBEG8D40D/EfHj/V27Hzx8aZNT2xubd685cmWpx/bsHH78zs/+PDjn06flSTJJoP1xINg6/jok89ee/3gkbffOdT9Fqzu7iMQ333v/f5vv/O5PeDxb23ZSgBx23+bAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chargebacks911 Blog",
          "title": "Chargebacks911 Blog",
          "src": "/static/a7fa0b38b301dd8c06661f1b563a0e45/d9199/cb911-marketing-4.png",
          "srcSet": ["/static/a7fa0b38b301dd8c06661f1b563a0e45/8ff5a/cb911-marketing-4.png 240w", "/static/a7fa0b38b301dd8c06661f1b563a0e45/e85cb/cb911-marketing-4.png 480w", "/static/a7fa0b38b301dd8c06661f1b563a0e45/d9199/cb911-marketing-4.png 960w", "/static/a7fa0b38b301dd8c06661f1b563a0e45/07a9c/cb911-marketing-4.png 1440w", "/static/a7fa0b38b301dd8c06661f1b563a0e45/29114/cb911-marketing-4.png 1920w", "/static/a7fa0b38b301dd8c06661f1b563a0e45/49853/cb911-marketing-4.png 2224w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Responsive Infographics 📊</h5>
        <p>Part of the content marketing strategy was to employ the use of infographics - <strong>the goal being for others to share the infographic to gain content traction</strong>.</p>
        <p>We thought that we can make that easier and device-friendly by using <OutboundLink rel="noopener noreferrer" rel="noopener noreferrer" underline="true" href="http://ai2html.org/" target="_blank" mdxType="OutboundLink">The New York Times' Adobe Illustrator plugin</OutboundLink> that would render the artbooards into multiple splices of HTML for specific breakpoints. We would then host the single HTML file and use our own custom JavaScript plugin to embed the infographics with a <strong>single line of code</strong>.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Embeddable Infographic</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsSAAALEgHS3X78AAACCElEQVQoz31Ty26bQBTNd3fdbRZV960XWVXqosqmm0ZdOJZfxLUwGMtJKgcbY142YBjPkx4Djh+JegWjGWbO3HPuuVwVRaGUyvOcEJJl2Y6QaiKlLKo9pTiWQqjiMq7wcs51Xdc0rdPpDB4eer3ecDgEHluMEK/VWrVaa9OMNE29Be92O4DH4/FgMDANYzGfR1GkysyBrruaBjrbKLJvb5Onp4rOEQxiwNe0T+hhwigF4WrJT+ZHcIXHmCXJ9y9fJ7qOuSxjXQaIpGkahmEcx4yxSzDOIXscBNNGw9V1SakSglLabrebzSZGFAKiut0urnhNVoMF+DC+8f27z58e+32swRBJDNOcWBbKYZax2Wwg8DJzrQplN83gcDeCxDFlDA9CnAuuweAc+HXEpcil40DkntFqxV9mqoThGPX9yoUjGLdWPoOeZVmgZxhGBWauy1YrQQgkBsPh9PpaXVglhQzCwPO9M05oLMcRKO/SkZwjOSTAbfXWqvq8VGAhuSrE3mXUbG/XNpVSqEO3nmlW+14oXPI8STs5Txnhf7zfd+5NN/6xIeFi7vx9mS0cx7bt6XSaJMk7YDuztPXPmIZiV/yybxqPH74tPrrxrNfR7u/hdBMO9/v90WgE89+nDY74vbZZmpEtRx05Wy5dz/OQFk1WtYM6YX5oz70aWX1Gu1CVU5njS/Hf+AfKQYr2dmigsgAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chargebacks911 Infographic",
          "title": "Chargebacks911 Infographic",
          "src": "/static/f0be0379642e7237a7ee850a0fd17cc2/1cfc2/cb911-infographic-1.png",
          "srcSet": ["/static/f0be0379642e7237a7ee850a0fd17cc2/8ff5a/cb911-infographic-1.png 240w", "/static/f0be0379642e7237a7ee850a0fd17cc2/e85cb/cb911-infographic-1.png 480w", "/static/f0be0379642e7237a7ee850a0fd17cc2/1cfc2/cb911-infographic-1.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Embeddable Infographic</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsSAAALEgHS3X78AAABe0lEQVQoz42SW0/CMBzF+Wj6MXlX4oMJEX3wyZgQTUzwQY1TuWyA7sLYunYta9fbbCFBF8B5lmzt0t/Z+Z+sVdUVB4E3Hvmz2dBxppPJzHXd4XD89qaEqHbU+r3RQoo8LyEsM8ARpCBlAJQImruitAH+cdF6sVxmEEmlqsPaDzNWRkGYLmNKqTZeUhq/ptjaBo+igDImOaerFd836n6Ycw4yOHq4IjBZV8BtiqryT08Kx7En6lO06mlZGIZxnOAc25MmbVU9Tybd4yPa71u7P+BtfEsKocrSLKIUvHY6zPM2gx2ElVJCSgaRgHBjkgIQLRYpIZAQvdN8DS4p9TMQXPbCdtt6MbZ5jwHIkqShsDQF0/n8y3Mjz9vmU4LOB7fu+0dz29Jc9iHtzEIYC16yBOZC/+Mn+cQ+JBnGaLNdFUVg+48xJgdhva4x9MPrm27v5eLJebSVa10UBTKCyCwaYJKTe+fubHCOKDawGaD6U7XYUsoc4Rzke7+zq2/8F6HqtRBNRAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chargebacks911 Infographic",
          "title": "Chargebacks911 Infographic",
          "src": "/static/9e39f231e61869ee3ebe192c18ea522a/1cfc2/cb911-infographic-2.png",
          "srcSet": ["/static/9e39f231e61869ee3ebe192c18ea522a/8ff5a/cb911-infographic-2.png 240w", "/static/9e39f231e61869ee3ebe192c18ea522a/e85cb/cb911-infographic-2.png 480w", "/static/9e39f231e61869ee3ebe192c18ea522a/1cfc2/cb911-infographic-2.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Embeddable Infographic</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsSAAALEgHS3X78AAAB7UlEQVQoz41Ty27UMBTNH/BLbPgGdkh8AAsk/qCzYsGC/gL7dkOlWaBRBcwGFlOkqlMxigidkpBM4jjxI47tG06SAYJURr1yrCvnnPs6dtBNjXxHrt/vZ8GESRN373vvnXOHyQPUSvp5QpsZpW8HHkogpVS+y5umoUnof8k0xC7e0/wBvQnc8lkDomtxJqQIozBJEiGEtfZOct8ksQ/+/Gnz6SVPb2vrW6VkXmrX3uxuvsVR/CMuefn/nvsOu0pTQ12+eHf18NHq8ZM83bXespoheZZlBwbmOt9icak+vnr9+fkLvtmUnG+3W621Maau60OZ/xiS667jUiZxXJYlZj6V4C/ZG0MT60HWkjHowbTtXiciPwy8N+fI7y9CoIuiYsxyboWAMI4IjshzpbXc7SCSHmg6z1uDaK2Fb1o/BA2s99+jKLq8vA1DKSVSIbtUKj07Y6uVkBJ84MBDC0lZbo6Osvl8LCdAjDTLzheLLxcXQFhjnFKNMdFs9vX0NKtrozW6EJxfr9dXYbg+Pi6Wyz0ZqVAMZECxGIy3Fmu8DxXnVVX1teDDuffjUFyP88PAvOcVL4qCMaa0Gn9DlTzLwOScQ6T+7lrLBgzOxW/NBjLvydjR80jG5ICDSNjHKlDdeAIkahxhwT1f350P4xeK8ZWnA6xOWAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Chargebacks911 Infographic",
          "title": "Chargebacks911 Infographic",
          "src": "/static/6d42dc30bb0b95572802666c5a9eff1b/1cfc2/cb911-infographic-3.png",
          "srcSet": ["/static/6d42dc30bb0b95572802666c5a9eff1b/8ff5a/cb911-infographic-3.png 240w", "/static/6d42dc30bb0b95572802666c5a9eff1b/e85cb/cb911-infographic-3.png 480w", "/static/6d42dc30bb0b95572802666c5a9eff1b/1cfc2/cb911-infographic-3.png 900w"],
          "sizes": "(max-width: 900px) 100vw, 900px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Marketing Design Assets 🎨</h5>
        <p>Some of my time was spent redesigning all of our marketing assets per our newly-documented visual styles. Those responsibilities consisted of redesigning our tradeshow booths, flyers, ebooks, banner ads, pitch decks, etc.</p>
        <p>Due to our increased marketing traction, we began seeing it was getting difficult to keep up with the items that needed to go out the door and the time we had on hand.</p>
        <p>In order to keep up with the scale of our marketing presence, we began creating templates and guides for some of our marketing items (eg. pitch decks, booklets, flyers) - which in turn gave other team members the ability to create an asset they needed without having to worry about designing it.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Digital Whitepapers</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "38.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABzElEQVQoz01SXUsbQRTd3yE+iQ+i4K/x1f/Qvvon9A/YBt8UDKQtSrChgaUtCo2ItCRCkk1iTWKy3zOzH7J7em42Dx24DDP3nHvPnDuW1hoqjksdBEh8H4a7VgpK7iXCEIaRyB7Hq7s1B1rwVRR5mkIpNbASANN6vbzd2sLXvT00dnYwareRliViFu4eHsLe3MTnjQ20Dg6EBMOca9v4tbuLb/v7+LS9XTiNBjJgYGljsLy/L7vn5/hxdYX2xQX+Og6SJEHouphdX+Pm5ASN42P8JEYKCmf68IBOrYZmvY4vZ2fFqNdDlucDK/I8TF5fS3c8xp/LS+QE50UBzSfNRiOEVNOn4ic2k7UqyFyPTYfMv3Q6eHGcIiaPVgwsFUXwfL/sMvHh6AgTAtMsW5EWiwWeeP5e+4j26SmcyTMMiYq+jalweHeH2/fv8GzbhXl7Ew49FJM5lIzAQIbBoZi18SEV/G61MF0uMXN9LJYeNK1QPM+bTaQcxHA+R55lBVc1FCMmK1VG7CqeuQSvpkvlUb8P7/ERMe8iNgp5pysB8MkLaJdYFgZBIcWrJ8tXiKJSQPJdTOVF5RXVGAGuc/q/LyMvkaYSzBV5nq8U/gMJoT+yCqoSmwAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ebook covers",
          "title": "Ebook covers",
          "src": "/static/d43d230e7a36d834ef654a706befb109/d9199/cb911-ebooks-2.png",
          "srcSet": ["/static/d43d230e7a36d834ef654a706befb109/8ff5a/cb911-ebooks-2.png 240w", "/static/d43d230e7a36d834ef654a706befb109/e85cb/cb911-ebooks-2.png 480w", "/static/d43d230e7a36d834ef654a706befb109/d9199/cb911-ebooks-2.png 960w", "/static/d43d230e7a36d834ef654a706befb109/07a9c/cb911-ebooks-2.png 1440w", "/static/d43d230e7a36d834ef654a706befb109/29114/cb911-ebooks-2.png 1920w", "/static/d43d230e7a36d834ef654a706befb109/836a3/cb911-ebooks-2.png 2905w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Ebooks available to download</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.16666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAADH0lEQVQ4y21Ua08aURD1bzdt0h/QtGniBxOTpq1YxCpWUFGEIg9XoFqqKJqK2qpYHgu7sgv7YJHTcy9so0lvMrt7szPnnjkzc6d2beB/lrNGUBxgx/SwqZrYavfke7NlINbsSluv60gZA+kn/EXclPjwN/53rv8gnbZVA7NLYUQSX7EST2CVFkkksbaTpmUQS2cQ2Eo+AZ16wsgF9ryxHQBY+6NhIxYDhkNY7TZcTcPg/h4PngfPpTNXMpujXwd5bwLosxInJJhO5Pwa4eOfCB+eYjq6iZVoVAa2OxqMXg9mv49ut4sO96PRA1K7CmJMPT94BLjHwxKajfWcgpPjMs7Pq1CbTWTTaYTDYRIcokOG92SnazpM04TlOPKgrKJg4679lKFA36TIhR+HsMlA13VYto3K6akEFEuA9fkPI+oz8uCQoVj7pRIiV3dPAfcIGG/oOODPLgMbjQbaZFSpVBAKhWSgZVnyfVmtoNmoAQNP7o9PKlg+u0RhCFnMMSD/RarXqFarkoVg2KNef+7usLi4CGeSnjsYoFH7DVNX4S8RE6LehQcfkI880Zd+nKB2e0uxO9BYTWG1eh3z8/MyXct2eJgFl9UVB7iTQ0RMoFBCcfQIcI/o4f1DaKqKHlMzKLpBkDpTDgaDTF+FpbfgWH3JckBzJ20jivchm0dRFMgH3GXK0W8leCyEY7I1dPYag8ybGywuhKCqLXjdeziUwyGQ0LPXH4Nr1HqOgAUfUGHRUmK8igfSwby6gFu/Rt9xobHfhIatVgt9y4ZGbUXRDMOASRsQvFW7xYftlMwy2xtiSrTMBltm9/BoLLwn0rHJwpYNPBcI4Khcxq9LVjKfR/Irx3B1FQtsp09fVjEzH0Qg/x05EsuQmBy9OCfk3fIK4tvbSHFOC8UijglydVGFks3g5YvnePb6LabXtjCTzOA9i/CxVMFC5QLR2xZSuo2drjtmmOEjzeGO3jTxqfwTc/tlzO4omF6P41VoGW+Cn/E6EMTS2ZUMkoFCdxJRJneA2Psj/O9yEM0tmtM30UqiP3MsZtYe6yNSehz8+Kby7S8idIXKXEArbAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ebooks",
          "title": "Ebooks",
          "src": "/static/fb77937088600475199c4f528c325fba/d9199/cb911-ebooks-1.png",
          "srcSet": ["/static/fb77937088600475199c4f528c325fba/8ff5a/cb911-ebooks-1.png 240w", "/static/fb77937088600475199c4f528c325fba/e85cb/cb911-ebooks-1.png 480w", "/static/fb77937088600475199c4f528c325fba/d9199/cb911-ebooks-1.png 960w", "/static/fb77937088600475199c4f528c325fba/07a9c/cb911-ebooks-1.png 1440w", "/static/fb77937088600475199c4f528c325fba/29114/cb911-ebooks-1.png 1920w", "/static/fb77937088600475199c4f528c325fba/ad164/cb911-ebooks-1.png 3680w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Tertiary assets for Chargebacks911</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEW0lEQVQ4yzVUf0yUdRh//26RsdXWWrbcnLCoVW4xcjaVHEn+SMfMfmitXM7McjGNTCJRHCYSRaJpXGBwIsoPQTvlx4EIGJzYDo67e7lf76+79947hDvguIPA59P3bvVuz55n3/fd83w+z+fzfblDb65G0Z538fWOt/DdtjfwzeZ1+GT9KuTmbEDBzs04smMD+jtvwh8chyiKEFwuOCwWyLIEpbEZ/OkykkWJZL8GUZKJ2532ApovnIH7TituHy3Ane+Po6XuEv68pEfPzesozz+E2zdaEPCpcA7dh2yzQ5EklJWWovh4EYaMXTR08TzdraqAY3SYuDMZr6PuRAG6dOW4suczVB7MRcO5UtRXlOJsyQ84vOtD9BoMiIRCUKw2yPwYFFlGesZrSHo8GclPPU1l6ak09MUOWAf6iNNnbUTdqULU5OfhRvZWdJeX4dpFHQzVOrRVXcDZA3vRd8uAcCQC1e/HOM9DdHsge7240nwNB9NXUs2zyVSzJRN2u524qi/34fP3c1C0eRNasrfg6k8lqC45id+Li1CxJhM5KStwSV+L2dkoVNbEN2KBn+UFtlMsLgKqj+x1euo4VwFBZjt0GepRuPs9ZKauwIF1a7E9cw02rV6FrJUvozj1RWQ89gj0VTpEolEoTBT32BhirI4y+iOdRjR1dpLk1yjOwOVyEddw+hgaSgqRtmwpnljyKFY8mYznkpOwdEkSPkhNwdrlz6ChrhZTMzPwMjG66uvR2tKKQcNNqG0dEAZNpAgCKYoCj8dDXGVRPgr270HGS2lYmbIMWemvYPe2bBzduwun9u3Eb199jPs9RvjithAE+FQVbibKaH8/Rv6ohe3Hn0nUVZOHIRcliTiz2YwhkwmtrUb8UnsLIm9H0GqB2NcLgbeBd/CYmgoj/iwSoaHwGM7nHUGHsRdjqgbB6yUnz5Pg8cR9ShxvtSIkK9AfPon9H32L4fsjCDMlJ+4OQOnugfjXIEIMlSCJUPv6UPF8GnKXpyBv41YMdHUnaAqMcsL0LHMCKyRGpe3t7ah+dT0G75gwTw8RY2hEixU+YxemgsEEwrDHjVGdDubLl2G+fgNBBmRufp78qkqe/xGG2fRoZAba1SY8MLQzJSU8CAQQmZ7GXCyKCSaG0+FI3A5V0zBqt0NlaBfY0IDqxfDfg+Rj1nEzbyZEWZybY5M98N0bgpeFxhY+F4slEM2yZnH0HhYO1jQ0OQl6+DDxzi278ev5cjQ160nz+2hcC0DTNOJ0lZXU3thEotPJwkWq6id9YyP1DAxQKBSiuLckpl58PwwlhScnKTozQw/Y9/I9E3mdPE2ws+lwmKLRKHGjNluCJt/bC0dnOzRGrebTvTiR8w562JmX3Yr/9gM3+9PwzB5x+uMsB5mgYYXdHlarjMFkMEhcgE0K2uw0Zh4mu81GNquVLJfryXQwj6xmM0mKkkAXD4ktPZ7HAwGaX/iHFgBaWFyk+ViMZmcjND09Tf8Cbif0VhqzHHEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Conventions booths, flyers, powerpoints",
          "title": "Conventions booths, flyers, powerpoints",
          "src": "/static/1c280de3deab579fd2b573b38cb8b7b0/d9199/cb911-print-1.png",
          "srcSet": ["/static/1c280de3deab579fd2b573b38cb8b7b0/8ff5a/cb911-print-1.png 240w", "/static/1c280de3deab579fd2b573b38cb8b7b0/e85cb/cb911-print-1.png 480w", "/static/1c280de3deab579fd2b573b38cb8b7b0/d9199/cb911-print-1.png 960w", "/static/1c280de3deab579fd2b573b38cb8b7b0/07a9c/cb911-print-1.png 1440w", "/static/1c280de3deab579fd2b573b38cb8b7b0/d61c2/cb911-print-1.png 1800w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Landing Pages and Emails ✉️</h5>
        <p>With the increase in the amount of new content being created from our writing and marketing teams, we saw the need to build landing page/email templates rather than build each page from scratch each time.</p>
        <p>Using HubSpot's templating language, HUBL, we were able to create a wide array of templates for newsletters, landing pages, and notifications - <strong>in turn helping us increase the speed at which we could deliver content to our audiences.</strong></p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAADuklEQVQ4y01TW0wcZRQeRSFqtOWtFTT1TV98aeKDMSZ9so0JPmnTQioxlRgxlFIENJpCfVQfTHzwQl+MSSOt7JYWsiKYtYUGF1hA2IUtu7C3mb3N7Fx2d/bC7vk8/5CY/smZf2bO+c98lzPSdFhH6GEUvtUAIoks9mUVkazhxF46T7Gcjq1MgbJ5g7KZDH397ffU29tP73d/SJ1d3fTue53Uf3mYUqk06bpO0kR3H7bbj2HnpRNIv9AG68SLsO7/jVKjDnU/Al2OU0DRkNF05HI5dLxzFk81t+BIayuaW1ogSRJef+MUlFQKhqFD+vHtc/A2N2Hp+HH8+8zTiHBBfn4e+WoVnl9/ofhOEJtKntKaTmouR2+eOk2vnTxJIwMDNNzf79y//MqrDkLDMEj67sxZuLiJp6kJC7z7OWo+H+4t+/HxR33oG/gCS7sKMnkDajaL3kuD+KCjA9/09Dhxke+7LnRTOpWGQ/m26y/s/fQDdsfHEb9+Hcnxn2HLSSSUFP6cncfMtAf+uApF1WHoeRQtC+HtHSiJBOLRKObdt5DXcgIdNE0jyV8EAaASR4XD5iiWy1StVKherzu5sHlAiYxKRp4bFouUUhRoqopSsQhTN1AuVyjPOafhg2wVsAtArQaxKlxkMQqNC0yL3x/UsG3UIasGCpYpPsS7hUajgUeW09DRULYbVC/bNHPnLk1NTdFvEzdpcWGR5ESSVlZWqVatkGzaFNoN034kglgiQRq7rR02ELoJ90lh1OFwmKR/FhdoxuOh9rZ2ajv2PD32+JM0du0rh+rQ8Gc0cfMWhR+GKBAIUDocxtaNG6RsbiLFBgnqgo0ww7ZtrK2tkXShswvnz5/Ds88dwdGjrWhqegKjnw6Bq/DJxR68dfoM5v6YxR4boO7v09LYGLY8HiR47uKxGOtXdiiLy8bGBkm/T06Sy+Umr9dL3nv3aXZujtaXlykbjdIaU56cdNGGz0c729uUjccQXVkhU9OQlmVkGKVdKgnnWZrqYUNhxkHBOpSWk8wBddanVigcql2tQGfNtgIBlPhPEIbYjMqhyjUFHh/Z7SaT85vBIEkHfMDmRIkLDD5oqBosHokCN7XZcYGgYJoIhULOB4TLJZajyCgLHGK32V22HUJn6c7daXimZ/73v1Su4NGBYPdx2+UWxc5zlmkm43HIjExJJnlPIsMui5zTcG19HUuLDzB6dQyXLg/iy8EhjI18jitDI7g6eg2rvmX4V1cRDAad2TMZrRgXkxmJsJidGHZeTsP/AMpu5hJD/tQPAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Landing Pages",
          "title": "Landing Pages",
          "src": "/static/16fb8246a4efebaba75e16cad6c59ab8/d9199/cb911-lp-1.png",
          "srcSet": ["/static/16fb8246a4efebaba75e16cad6c59ab8/8ff5a/cb911-lp-1.png 240w", "/static/16fb8246a4efebaba75e16cad6c59ab8/e85cb/cb911-lp-1.png 480w", "/static/16fb8246a4efebaba75e16cad6c59ab8/d9199/cb911-lp-1.png 960w", "/static/16fb8246a4efebaba75e16cad6c59ab8/07a9c/cb911-lp-1.png 1440w", "/static/16fb8246a4efebaba75e16cad6c59ab8/29114/cb911-lp-1.png 1920w", "/static/16fb8246a4efebaba75e16cad6c59ab8/d2c28/cb911-lp-1.png 4000w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      